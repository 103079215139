export const LocalStorageKeys = {
  CLIENT_ID: 'clientId',
  LOGIN_PREFERENCE: 'loginPreference',
  REDIRECT_PATH: 'redirectPath',
  SELECTED_MACHINE: 'selectedMachine',
  SELECTED_LANGUAGE_CODE: 'selectedLanguageCode.v2',
  PLANNER_ORDERS_FILTERS: 'plannerOrdersFilters',
  FOREMAN_LINE_STATUS_FILTERS: 'foremanLineStatusFilters',
  FOREMAN_DASHBOARD_LINE_STATUS_FILTERS: 'foremanDashboardLineStatusFilters.v2',
  MAINTENANCE_DASHBOARD_LINE_STATUS_FILTERS: 'maintenanceDashboardLineStatusFilters.v2',
  MAINTENANCE_LINE_STATUS_FILTERS: 'maintenanceLineStatusFilters',
  FOREMAN_FILES_FILTERS: 'foremanFilesFilters.v2',
  FOREMAN_IDLE_REASONS_FILTERS: 'foremanIdleReasonsFilters',
  FOREMAN_IDLE_REASONS_VIEW_MODE: 'foremanIdleReasonsViewMode',
  FOREMAN_FAILURE_REASONS_FILTERS: 'foremanFailureReasonsFilters',
  FOREMAN_FAILURE_REASONS_VIEW_MODE: 'foremanFailureReasonsViewMode',
  FOREMAN_RETOOLS_FILTERS: 'foremanRetoolsFilters',
  FOREMAN_RETOOLS_VIEW_MODE: 'foremanRetoolsViewMode',
  FOREMAN_WORK_WITH_FAILURE_REASONS_FILTERS: 'foremanWorkWithFailureReasonsFilters',
  FOREMAN_WORK_WITH_FAILURE_REASONS_VIEW_MODE: 'foremanWorkWithFailureReasonsViewMode',
  FOREMAN_STATUSES_TIMELINE_FILTERS: 'foremanStatusesTimelineFilters',
  FOREMAN_STATUSES_TIMELINE_VIEW_MODE: 'foremanStatusesTimelineViewMode',
  FOREMAN_PERFORMANCE_DROPS_FILTERS: 'foremanPerformanceDropsFilters',
  FOREMAN_PERFORMANCE_DROPS_CHART_FILTERS: 'foremanPerformanceDropsChartFilters',
  FOREMAN_ORDERS_FILTERS: 'foremanOrdersFilters',
  OPERATOR_WORKSTATION_HISTORY_FILTERS: 'operatorWorkstationHistoryFilters',
  FOREMAN_PRODUCTION_PLAN_DATE_MODE: 'foremanProductionPlanDateMode',
  FOREMAN_PRODUCTION_PLAN_FILTERS: 'foremanProductionPlanFilters',
  FOREMAN_PRODUCTION_PLAN_VIEW_MODE: 'foremanProductionPlanViewMode',
  FOREMAN_PRODUCTION_PLAN_CHART_FILTERS: 'foremanProductionPlanChartFilters',
  FOREMAN_OEE_CHART_FILTERS: 'foremanChartOeeFilters',
  FOREMAN_OEE_FILTERS: 'foremanOeeFilters',
  FOREMAN_PRODUCTIVITY_FILTERS: 'foremanProductivityFilters',
  FOREMAN_REWORKS_FILTERS: 'foremanReworksFilters',
  FOREMAN_PRODUCTIVITY_CHART_FILTERS: 'foremanProductivityChartFilters',
  FOREMAN_COMPONENT_SCRAPS_CLASSIFICATION_FILTERS: 'foremanComponentScrapsClassificationFilters',
  FOREMAN_COMPONENT_SCRAPS_CLASSIFICATION_VIEW_MODE: 'foremanComponentScrapsClassificationViewMode',
  FOREMAN_SCRAPS_CLASSIFICATION_FILTERS: 'foremanScrapsClassificationFilters',
  FOREMAN_SCRAPS_CLASSIFICATION_VIEW_MODE: 'foremanScrapsClassificationViewMode',
  FOREMAN_OUTPUT_FILTERS: 'foremanOutputFilters',
  FOREMAN_OUTPUT_VIEW_MODE: 'foremanOutputViewMode',
  FOREMAN_YAMAZUMI_FILTERS: 'foremanYamazumiFilters',
  FOREMAN_PDCA_SELECTED_LINE: 'foremanPdcaSelectedLineId',
  FOREMAN_PDCA_VIEW_TYPE: 'foremanPdcaViewType',
  FOREMAN_PDCA_EXPANDED_TASKS: 'foremanPdcaExpandedTasks',
  FOREMAN_DASHBOARD_SCRAPS_CLASSIFICATION_FILTERS: 'foremanDashboardScrapsClassificationFilters',
  FOREMAN_DASHBOARD_SCRAPS_CLASSIFICATION_VIEW_MODE: 'foremanDashboardScrapsClassificationViewMode',
  FOREMAN_DASHBOARD_PRODUCTION_PLAN_FILTERS: 'foremanDashboardProductionPlanFilters',
  FOREMAN_DASHBOARD_SELECTED_WORKSTATION_ID: 'foremanDashboardSelectedWorkstationId',
  FOREMAN_DASHBOARD_PRODUCTION_PLAN_VIEW_MODE: 'foremanDashboardProductionPlanViewMode',
  FOREMAN_DASHBOARD_PRODUCTION_PLAN_CHART_FILTERS: 'foremanDashboardProductionPlanChartFilters',
  FOREMAN_DASHBOARD_PDCA_VIEW_TYPE: 'foremanDashboardPdcaViewType',
  FOREMAN_DASHBOARD_PDCA_EXPANDED_TASKS: 'foremanDashboardPdcaExpandedTasks',
  FOREMAN_DASHBOARD_ORDERS_FILTERS: 'foremanDashboardOrdersFilters',
  FOREMAN_OPERATORS_WORK_DATA_FILTERS: 'foremanOperatorsWorkDataFilters',
  MAINTENANCE_PDCA_VIEW_TYPE: 'maintenancePdcaViewType',
  MAINTENANCE_PDCA_EXPANDED_TASKS: 'maintenancePdcaExpandedTasks',
  MAINTENANCE_BOARD_PDCA_VIEW_TYPE: 'maintenanceBoardPdcaViewType',
  MAINTENANCE_BOARD_PDCA_EXPANDED_TASKS: 'maintenanceBoardPdcaExpandedTasks'
};
